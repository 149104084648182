<template>
  <div class="row">
    <div class="col-12 col-sm-12">
      <div class="row">
        <div class="col-12 col-sm-12">
          <h2 class="mb-25px">
            My Account
          </h2>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-12 col-sm-12">
          <div class="card p-60px">
            <div class="row">
              <div class="col-12 col-sm-12">
                <grid-loader
                  class="expertel-loader"
                  color="#ff502d"
                  :loading="loading"
                  :size="20"
                  sizeUnit="px"
                ></grid-loader>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <div class="col-12 col-sm-12 col-md-6">
                <h2>Customer Information</h2>
                <div class="mt-4 settings-form">
                  <div class="input-container">
                    <label for="">Business Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.contact_name"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Contact Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.name"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.email"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.phone"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <h2>Locations Details</h2>
                <div class="mt-4 settings-form">
                  <div class="input-container">
                    <label for="">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.address"
                      disable
                    />
                  </div>
                  <div class="input-container">
                    <label for="">City</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.city"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Province</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.province"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Postal Code</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userInfo.postal_code"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <div class="col-12 col-sm-12 col-md-6">
                <h2>Accounts</h2>
                <div class="mt-4 settings-form">
                  <div class="input-container">
                    <label for="">Provider</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Bell"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Account Nº</label>
                    <input
                      type="text"
                      class="form-control"
                      value="25839"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Billing Day</label>
                    <input
                      type="text"
                      class="form-control"
                      value="1"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Reference</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Reference_800"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <h2>Billing Details</h2>
                <div class="mt-4 settings-form">
                  <div class="input-container">
                    <label for="">Service Plan</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Gold"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Subscribers</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Teller"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Activation Date</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Dec 31, 1969"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Next Billing Date</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Dec 31, 1969"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <div class="col-12 col-sm-12">
                <h2>Cost Centre Breakdown</h2>
                <div class="mt-4 bg-gray d-flex flex-column px-4 py-4">
                  <div>
                    <a
                      :href="cost_centre_data.files.cost_centre_url"
                      class="link color-orange"
                      >Download</a
                    >
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="upload-file justify-content- my-4">
                      <input
                        id="file"
                        type="file"
                        @change.prevent="onChange()"
                        ref="file"
                      />
                      <button class="btn button-outline-expertel-gray">
                        Choose file
                      </button>
                      <p class="ml-3 mb-0 color-gray" v-if="this.file == ''">
                        No file chosen
                      </p>
                      <p class="ml-3 mb-0 color-gray" v-else>{{ file.name }}</p>
                    </div>
                    <!--<button class="button-expertel ml-4">Upload</button>-->
                    <a
                      href="#"
                      class="button-expertel ml-4"
                      @click.prevent="uploadCostCentreList()"
                      >Upload</a
                    >
                  </div>
                  <div>
                    <a href="#" class="link color-orange"
                      >Non-Business Usage Thresholds</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      cost_centre_data: [],
      loading: false,
      userInfo: [],
      file: "",
      name: ""
    };
  },
  async created() {
    const self = this;
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getUsers();
      }
    } else {
      this.$router.push("/");
    }

    //GET THE COST CENTRE DATA AND SAVE IT IN [cost_centre_data]
    await this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/cost_centres`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.cost_centre_data = response.data;
      })
      .catch(error => this.makeToast("ERROR", error, "danger"));
  },
  methods: {
    getUsers() {
      const self = this;

      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/info`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.userInfo = response.data;
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    onChange() {
      this.file = this.$refs.file.files[0];
    },
    uploadCostCentreList() {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      formData.append("list", this.file);

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/cost_centres/upload`,
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR", response.data.errors, "danger");
            self.loading = false;
          } else {
            self.makeToast(
              "List of Cost Centres uploaded successfully",
              "success"
            );
            self.file = "";
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>
